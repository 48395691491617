<template>
  <v-card>
    <v-toolbar dark dense color="primary">
      <v-icon>fad fa-camera-movie</v-icon>
      <v-toolbar-title class="ml-4">New Team</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Team Name*"
              required
              v-model="team.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Team Email"
              v-model="team.email"
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Legal middle name"
              hint="example of helper text only on focus"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Legal last name*"
              hint="example of persistent helper text"
              persistent-hint
              required
            ></v-text-field>
          </v-col> -->

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="City"
              v-model="team.address.city"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="State/Province"
              v-model="team.address.state"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
            v-model="team.address.country"
            :items="countries"
            item-value="code"
            item-text="name"
            label="Country"
          ></v-autocomplete>
            <!-- <v-select
              :items="countries"
              label="Country*"

              v-model="team.address.country"
              required
            ></v-select> -->
          </v-col>

          <v-col cols="12">
            <v-textarea
              clearable
              clear-icon="fad fa-times-circle"
              label="Team Description"
              v-model="team.description"
            ></v-textarea>
          </v-col>

          <!-- <v-col cols="12" sm="6">
            <v-select
              :items="['0-17', '18-29', '30-54', '54+']"
              label="Age*"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="[
                'Skiing',
                'Ice hockey',
                'Soccer',
                'Basketball',
                'Hockey',
                'Reading',
                'Writing',
                'Coding',
                'Basejump'
              ]"
              label="Interests"
              multiple
            ></v-autocomplete>
          </v-col> -->
        </v-row>
      </v-container>
      <small>*indicates required field</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="save(false)"> Save </v-btn>
      <v-btn color="blue darken-1" text @click="save(true)">
        Save &amp; New</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { db, firebase } from "./../services/firebase"
import { get } from "vuex-pathify"
import axios from "axios"

export default {
  name: 'TeamEdit',
  props: [],
  data() {
    return {
      team: {
        name: 'My New Team',
        email: '',
        slug: '',
        avatar: {
          src: ''
        },
        address: {
          city: '',
          state: '',
          country: ''
        },
        description: ''
      },
      countries:[]
    }
  },
  created () {
    this.fetchCountries();
  },
  computed: {
    userId: get('auth/userId')
  },
  methods: {
    fetchCountries() {
      axios.get('/lookup/country.json').then(response => {
        this.countries = response.data
      })
    },
    cancel() {
      this.$emit('close')
    },
    async save(newAfterSave) {

      let newTeam = {...this.team, roles: { [this.userId]:'owner' }}
      
      await db.collection('teams').add(newTeam);
      
      this.team = {
          name: 'My New Team',
          email: '',
          slug: '',
          avatar: {
            src: ''
          },
          address: {
          city: '',
          state: '',
          country: ''
        },
        description: ''
      }

      //await db.collection('teams').add(this.team)
      if (newAfterSave) {
        return;
      }

      this.$emit('close')
    }
  }
}
</script>