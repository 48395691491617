<template>
  <v-list dense class="mt-2">
    <v-list-item
      v-for="item in items"
      :key="item.title"
      :to="{ name: item.target }"
      exact
    >
      <v-list-item-icon>
        <v-icon class="fa-fw">{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'SidebarMenu',
  props: ['items'],
  methods: {
    navigate(target) {
      if (this.$route.name !== target) {
        this.$router.push({ name: target })
      }
    }
  }
}
</script>