<template>
  <v-container>
    <v-alert outlined type="warning" prominent border="left">
      Paul - this page should be admin shortcuts to important stuff or commonly
      used admin functions. We may not have antyhing to begin with, but at some
      point you will probably want some top level metrics, or shortcuts to
      create things etc. This is where we can put all of that.
    </v-alert>
    <v-row class="mb-4">
      <v-col cols="12">
        <div>
          <v-btn color="info" fab x-large dark class="mb-4" @click="addTeam">
            <i class="fad fa-camera-movie fa-2x"></i>
            <i
              class="fas fa-plus fa-stack-1x"
              data-fa-transform="right-18 up-12"
              style="color: Tomato"
            ></i>
          </v-btn><br/>
          <span>New Team</span>
        </div>
      </v-col>
    </v-row>


    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      transition="dialog-top-transition"
    >
      <TeamEdit @close="closeDialog"></TeamEdit>
    </v-dialog>


  </v-container>
</template>

<script>
import TeamEdit from "./../../components/TeamEdit"

export default {
  name: "AdminHome",
  components: {
    TeamEdit,
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    addTeam() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    }
  },
}
</script>