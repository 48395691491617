<template>
  <v-container class="pt-0">
    <p>This page shows the application color scheme as currently set. You can change the values of the colos to see 
      how they will work together, but to make the change effective we will need to update the actual values 
      in code and do a new code push.</p>
       <v-card class="mt-4" max-width="900">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Light Theme</v-toolbar-title>
      </v-toolbar>

      <v-row align="center" class="px-4">
        <v-col cols="4" sm="4">
          <v-text-field v-model="primary" :label="primaryLabel"></v-text-field>
          <v-text-field
            v-model="secondary"
            :label="secondaryLabel"
          ></v-text-field>
          <v-text-field v-model="info" :label="infoLabel"></v-text-field>
          <v-text-field v-model="success" :label="successLabel"></v-text-field>
          <v-text-field v-model="warning" :label="warningLabel"></v-text-field>
          <v-text-field v-model="error" :label="errorLabel"></v-text-field>
        </v-col>

        <v-col class="text-center" cols="4" sm="4">
          <div class="my-2">
            <v-btn depressed small :color="newPrimary" dark
              >Primary Button</v-btn
            >
          </div>
          <div class="my-2">
            <v-btn depressed small :color="newSecondary" dark
              >Secondary Button</v-btn
            >
          </div>
          <div class="my-2">
            <v-btn depressed small :color="newInfo" dark>Info Button</v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small :color="newSuccess" dark
              >Success Button</v-btn
            >
          </div>
          <div class="my-2">
            <v-btn depressed small :color="newWarning" dark
              >Warning Button</v-btn
            >
          </div>
          <div class="my-2">
            <v-btn depressed small :color="newError" dark>Error Button</v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small disabled>Disabled Button</v-btn>
          </div>
        </v-col>

        <v-col class="text-center" cols="4" sm="4">
          <div class="my-2">
            <v-btn depressed small fab :color="newPrimary"></v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small fab :color="newSecondary"></v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small fab :color="newInfo"></v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small fab :color="newSuccess"></v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small fab :color="newWarning"></v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small fab :color="newError"></v-btn>
          </div>
          <div class="my-2">
            <v-btn depressed small fab disabled></v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="pa-4">
        <v-alert :color="newPrimary" dark>I'm a primary alert.</v-alert>
        <v-alert :color="newSecondary" dark>I'm a secondary alert.</v-alert>
        <v-alert :color="newInfo">I'm an info alert.</v-alert>
        <v-alert :color="newSuccess">I'm a success alert.</v-alert>
        <v-alert :color="newWarning">I'm a warning alert.</v-alert>
        <v-alert :color="newError">I'm an error alert.</v-alert>
      </div>
    </v-card>

    <div style="margin-bottom:100px;" class="mt-4">
      <h3>Current Application Settings</h3>
      <v-btn depressed small fab color="primary" class="mr-3">P</v-btn>
      <v-btn depressed small fab color="secondary" class="mr-3">S</v-btn>
      <v-btn depressed small fab color="info" class="mr-3">I</v-btn>
      <v-btn depressed small fab color="success" class="mr-3">S</v-btn>
      <v-btn depressed small fab color="warning" class="mr-3">W</v-btn>
      <v-btn depressed small fab color="error" class="mr-3">E</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Colors",
  data() {
    return {
      primary: this.$vuetify.theme.themes.light.primary,
      secondary: this.$vuetify.theme.themes.light.secondary,
      info: this.$vuetify.theme.themes.light.info,
      success: this.$vuetify.theme.themes.light.success,
      warning: this.$vuetify.theme.themes.light.warning,
      error: this.$vuetify.theme.themes.light.error
    }
  },
  computed: {
    newPrimary() {
      return this.isHexColor(this.primary)
        ? this.primary
        : this.$vuetify.theme.themes.light.primary
    },
    newSecondary() {
      return this.isHexColor(this.secondary)
        ? this.secondary
        : this.$vuetify.theme.themes.light.secondary
    },
    newInfo() {
      return this.isHexColor(this.info)
        ? this.info
        : this.$vuetify.theme.themes.light.info
    },
    newSuccess() {
      return this.isHexColor(this.success)
        ? this.success
        : this.$vuetify.theme.themes.light.success
    },
    newWarning() {
      return this.isHexColor(this.warning)
        ? this.warning
        : this.$vuetify.theme.themes.light.warning
    },
    newError() {
      return this.isHexColor(this.error)
        ? this.error
        : this.$vuetify.theme.themes.light.error
    },
    primaryLabel() {
      return (
        "Primary (default: " + this.$vuetify.theme.themes.light.primary + ")"
      )
    },
    secondaryLabel() {
      return (
        "Secondary (default: " +
        this.$vuetify.theme.themes.light.secondary +
        ")"
      )
    },
    infoLabel() {
      return "Info (default: " + this.$vuetify.theme.themes.light.info + ")"
    },
    successLabel() {
      return (
        "Success (default: " + this.$vuetify.theme.themes.light.success + ")"
      )
    },
    warningLabel() {
      return (
        "Warning (default: " + this.$vuetify.theme.themes.light.warning + ")"
      )
    },
    errorLabel() {
      return "Error (default: " + this.$vuetify.theme.themes.light.error + ")"
    }
  },
  methods: {
    isHexColor(hex) {
      hex = hex.length && hex[0] == "#" ? hex.slice(1) : hex

      return (
        typeof hex === "string" &&
        hex.length === 6 &&
        !isNaN(Number("0x" + hex))
      )
    }
  }
}
</script>
