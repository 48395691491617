<template>
  <v-app>
    <v-navigation-drawer app  permanent color="error" dark fixed>
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Ridgewolf Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>

      <SidebarMenu :items="items"></SidebarMenu>
      
      <v-spacer></v-spacer>
    </v-navigation-drawer>

    <v-app-bar color="white" app dense flat elevate-on-scroll elevation="1">
      <h3 class="d-flex align-center">{{ pageTitle }}</h3>
      <v-spacer></v-spacer>
      <MessageCenterPopup></MessageCenterPopup>
      <ProfilePopup></ProfilePopup>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ProfilePopup from './../components/ProfilePopup'
import MessageCenterPopup from './../components/MessageCenterPopup'
import SidebarMenu from './../components/SidebarMenu'

import { get } from 'vuex-pathify'
import { routeNames } from './../router'
import { logout } from './../services/firebase'

export default {
  name: 'AdminLayout',
  components: {
    ProfilePopup,
    MessageCenterPopup,
    SidebarMenu
  },
  data() {
    return {
      items: [
        { title: 'admin home', icon: 'fad fa-home', target: routeNames.admin.home },
        { title: 'colors', icon: 'fad fa-palette', target: routeNames.admin.colors },
        // { title: 'administrators', icon: 'fad fa-user-shield', target: routeNames.admin. },
        { title: 'teams', icon: 'fad fa-users', target: routeNames.admin.teams },
        
      ]
    }
  },
  computed: {
    pageTitle: get('app/pageTitle')
  }
}
</script>
