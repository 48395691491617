<template>
  <v-container class="pt-0">
   
    <v-row>
      <v-col cols="12">
        <TeamEdit></TeamEdit>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="teams"
          :search="search"
          :loading="loading"
          dense
          loading-text="Loading... Please wait"
          class="mt-7"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click="gotoFamily(item)"
              >fas fa-pen-alt</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-sheet class="pa-4 mx-auto" max-width="960">
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn outlined small color="primary" class="mt-3">New Team</v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { db } from './../../services/firebase'
import TeamEdit from './../../components/TeamEdit'

export default {
  name: 'AdminTeams',
  components: {
    TeamEdit
  },
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        {
          text: 'Team Name',
          align: 'start',
          value: 'name'
        },
        { text: 'Actions', align: 'end', value: 'action', sortable: false }
      ],
      teams: []
    }
  },
  firestore: {
    teams: db.collection('teams').orderBy('name')
  },
  methods: {
    getTeams() {
      db.collection('teams')
        .get()
        .then((querySnapshot) => {
          this.teams = querySnapshot.docs.map((doc) => doc.data)
        })
    }
  }

  // firestore: {
  //   teams: db.collection('teams').get().then(querySnapshot => {
  //     this.teams = querySnapshot.docs.map(doc => doc.data())
  //     this.loading = false
  //   })
  // }

  // firestore() {
  //   return {
  //     teams: db
  //       .collection('teams')
  //       .get()
  //       .then(querySnapshot => {
  //         this.teams = querySnapshot.docs.map(doc => doc.data())
  //         this.loading = false
  //       })
  //   }
  // }
}
</script>
