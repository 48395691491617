<template>
  <v-menu bottom min-width="250px" min-height="200px" rounded offset-y v-if="isAuthenticated">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>fad fa-bell</v-icon>
      </v-btn>
    </template>
    <v-card class="py-4">
      <h5 class="d-flex align-center px-2 pb-2">Message Center</h5>
      <v-divider></v-divider>
      <div v-if="messages.length == 0" class="pa-2 body-2">You have no messages at this time</div>
    </v-card>
  </v-menu>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'MessageCenterPopup',
  computed: {
    isAuthenticated: get('auth/isAuthenticated')
  },
  data() {
    return {
      messages: []
    }
  },
  methods: {
    
  }
}
</script>
